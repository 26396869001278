import styled from '@emotion/styled'
import { ChangeEvent } from 'react'
import {
  Control as ControlType,
  FieldValues,
  Path,
  useController,
} from 'react-hook-form'

import { maxWidth, minWidth } from '@emico/styles'

import theme from '../../theme'
import { getTypography } from '../../theme/typography'

const IncrementButtonContainer = styled('div', {
  shouldForwardProp: (prop: string) =>
    !['active', 'hasLabel', 'isSolarPanelMobile', 'highlight'].includes(prop),
})<{
  active: boolean
  hasLabel: boolean
  isSolarPanelMobile: boolean
  highlight: boolean
}>`
  width: 100%;
  min-width: 144px;
  max-width: ${({ isSolarPanelMobile }) =>
    isSolarPanelMobile ? '152px' : '180px'};
  height: ${({ hasLabel }) => (hasLabel ? `65px` : '48px')};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ hasLabel }) => hasLabel && `20px`};
  border: ${({ active, hasLabel, highlight }) =>
    !hasLabel && !highlight
      ? 'none'
      : active
        ? `2px solid ${theme.colors.accentGreen}`
        : `1px solid ${theme.colors.gray}`};

  & div {
    display: ${({ hasLabel }) => !hasLabel && 'flex'};
  }

  &:hover {
    span {
      display: ${({ hasLabel }) => hasLabel && 'none'};
    }

    div {
      display: ${({ hasLabel }) => hasLabel && 'flex'};
    }
  }

  @media ${minWidth('lg')} {
    min-width: 150px;
  }
`

const IncrementButtonText = styled('span', {
  shouldForwardProp: (prop: string) => !['active'].includes(prop),
})<{
  active: boolean
}>`
  ${getTypography('bodyGalano', 14, 400)};
  ${({ active }) =>
    active &&
    `
    font-weight: 700;
  `}
`

const IncrementButtonInteraction = styled('div', {
  shouldForwardProp: (prop: string) =>
    !['hasLabel', 'isSolarPanelMobile'].includes(prop),
})<{
  hasLabel: boolean
  isSolarPanelMobile: boolean
}>`
  ${getTypography('bodyGalano', 14, 400)};
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  border: ${({ hasLabel, isSolarPanelMobile }) =>
    !hasLabel ||
    (!isSolarPanelMobile &&
      `1px solid
      ${theme.colors.gray}`)};

  @media ${minWidth('md')} {
    border: ${({ hasLabel }) =>
      hasLabel
        ? 'none'
        : `1px solid
      ${theme.colors.gray}`};
  }

  @media ${maxWidth('md')} {
    border: ${({ isSolarPanelMobile }) =>
      !isSolarPanelMobile &&
      `1px solid
      ${theme.colors.gray}`};
  }
`

const IncrementButtonAction = styled('button', {
  shouldForwardProp: (prop: string) =>
    !['hasLabel', 'isSolarPanelMobile'].includes(prop),
})<{
  hasLabel: boolean
  isSolarPanelMobile: boolean
}>`
  cursor: pointer;
  width: ${({ hasLabel }) => (hasLabel ? '37px' : '46px')};
  height: ${({ hasLabel }) => (hasLabel ? '37px' : '46px')};
  border: ${({ hasLabel, isSolarPanelMobile }) =>
    !hasLabel
      ? !isSolarPanelMobile
        ? 'none'
        : `1px solid ${theme.colors.gray}`
      : `1px solid ${theme.colors.gray}`};
  box-sizing: border-box;
  background: none;
  font-weight: 600;
  font-size: 17px;

  @media ${minWidth('md')} {
    border: ${({ hasLabel }) =>
      !hasLabel ? 'none' : `1px solid ${theme.colors.gray}`};
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    &:not(:disabled) {
      background: ${theme.colors.black};
      color: ${theme.colors.white};
    }
  }
`

const Input = styled.input`
  border: none;
  box-sizing: border-box;
  height: 100%;
  display: inline-block;
  text-align: center;
  max-width: 30%;
  -moz-appearance: textfield;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &:focus {
    outline: none;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${theme.colors.black};
  }
`

interface Props<T extends FieldValues> {
  label?: string
  value: number
  control: ControlType<T>
  name: Path<T>
  isObject?: boolean
  hasInput?: boolean
  id?: string | null
  sku?: string | null
  isSolarPanelMobile?: boolean
  highlight?: boolean
  isPositiveValueRequired?: boolean
}

export const IncrementButton = <T extends FieldValues>({
  name,
  label,
  value,
  control,
  isObject,
  hasInput,
  id,
  sku,
  isSolarPanelMobile = false,
  highlight = false,
  isPositiveValueRequired = false,
}: Props<T>) => {
  const { field } = useController({
    name: name ?? '',
    control,
  })

  const hasLabel = Boolean(label)
  const minimumValue = isPositiveValueRequired ? 1 : 0

  const handlePlus = () => {
    if (isObject) {
      field.onChange({
        id,
        sku,
        amount: value + 1,
      })

      return
    }

    field.onChange(value + 1)
  }

  const handleMinus = () => {
    if (value > minimumValue) {
      if (isObject) {
        field.onChange({
          id,
          sku,
          amount: value - 1,
        })

        return
      }

      field.onChange(value - 1)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)

    if (value >= minimumValue) {
      if (isObject) {
        field.onChange({
          id,
          sku,
          amount: value,
        })

        return
      }

      field.onChange(value)
    }
  }

  return (
    <IncrementButtonContainer
      active={value > 0}
      hasLabel={hasLabel}
      isSolarPanelMobile={isSolarPanelMobile}
      highlight={highlight}
    >
      {hasLabel && (
        <IncrementButtonText active={value > 0}>
          {label} {value > 0 && `(${value})`}
        </IncrementButtonText>
      )}
      <IncrementButtonInteraction
        hasLabel={hasLabel}
        isSolarPanelMobile={isSolarPanelMobile}
      >
        <IncrementButtonAction
          hasLabel={hasLabel}
          onClick={handleMinus}
          isSolarPanelMobile={isSolarPanelMobile}
          disabled={value <= minimumValue}
        >
          -
        </IncrementButtonAction>
        {hasInput ? (
          <Input
            value={value > 0 ? value : ''}
            onChange={handleChange}
            type="number"
            placeholder="0"
          />
        ) : (
          <>{value}</>
        )}
        <IncrementButtonAction
          hasLabel={hasLabel}
          onClick={handlePlus}
          isSolarPanelMobile={isSolarPanelMobile}
        >
          +
        </IncrementButtonAction>
      </IncrementButtonInteraction>
    </IncrementButtonContainer>
  )
}
